import { ArrowRightAlt } from '@mui/icons-material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ThemeProvider from '@mui/system/ThemeProvider';
import { useEffect, useRef, useState } from 'react';
import Typewriter from 'typewriter-effect';
import ArrowRightIcon from './components/ArrowRightIcon';
import ContactForm from './components/ContactForm';
import LinkedinIcon from './components/LinkedinIcon';
import LogoButton from './components/LogoButton';
import useIntersection from './components/useIntersection';
import * as color from './constants/colors';
import theme, { mixinFontSizes } from './constants/theme';
import { santoroScriptFamily } from './constants/typography';

const styles = {
  link: {
    display: 'inline-block',
    lineHeight: 0,
  },
  bgImageDark: {
    backgroundImage: 'url("images/bg-dark.jpeg")',
  },
  bgImageLight: {
    backgroundImage: 'url("images/bg-light.jpeg")',
  },
  section: {
    pt: 2.875,
    pb: 3.5,
  },
  sectionDark: {
    color: color.onBackgroundDark,
    backgroundColor: color.backgroundDark,
  },
  sectionLight: {
    color: color.onBackgroundLight,
    backgroundColor: color.backgroundLight,
  },
  stripe: {
    color: color.onPrimaryLight,
    backgroundColor: color.primaryLight,
    height: 8,
    my: 1,
  },
  s1Logo: {
    height: { xs: 56, sm: 64, lg: 72 },
    width: { xs: 56, sm: 64, lg: 72 },
  },
  s1LogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    pt: 4.75,
    pb: 2,
  },
  s1TitleContainer: {
    mt: 0.5,
    pb: 0.75,
  },
  s1Title: {
    textShadow: '3px 3px 2px rgba(0, 0, 0, 0.22)',
  },
  s1TitleSubtitle: {
    color: color.neutral25,
    ...mixinFontSizes({ xs: '1.2375rem', sm: '1.375rem', lg: '1.375rem' }),
    fontWeight: 600,
    letterSpacing: '0.165px',
    lineHeight: 1.2727,
  },
  s2Container: {
    display: 'flex',
    flexDirection: 'column',
    mx: 'auto',
    mb: 2.25,
    pt: 3.5,
  },
  s2Content: {
    display: 'flex',
  },
  s2TitleBox: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'center',
    pl: 7,
    mb: 3.125,
  },
  s2Title: {
    color: color.neutral95,
    pl: 0.375,
  },
  s2TitleLets: {
    color: color.secondaryDark,
    fontFamily: santoroScriptFamily,
    ...mixinFontSizes({ xs: '1.625rem', sm: '1.625rem', lg: '1.75rem' }),
    letterSpacing: '2.25px',
    lineHeight: 1.3929,
    transform: 'rotate(-7deg)',
    mr: 1,
    mt: -0.5,
  },
  s2TitleLetsPos: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  s2TextBox: {
    borderLeft: `1px solid ${color.outlineDark}`,
    flexGrow: 1,
    pl: 2.5,
    pb: 1.5,
  },
  s2Text: {
    mb: 2.75,
  },
  s2Arrow: {
    color: color.secondaryDark,
    height: '1.875rem',
    width: '1.875rem',
    mr: 2,
  },
  s2PointsBox: {
    display: { xs: 'none', md: 'block' },
    flexBasis: '38%',
    flexShrink: 0,
  },
  s2PointsBoxXs: {
    display: { xs: 'block', md: 'none' },
    mb: 2.75,
    pr: 2.5,
  },
  s2PointBox: {
    alignItems: 'center',
    display: 'flex',
    my: 1,
    pr: 1.25,
  },
  s2PointText: {
    color: color.neutral95,
    letterSpacing: '0.75px',
  },
  s2ButtonBox: {
    display: 'flex',
    pt: 2.5,
    mb: 1,
  },
  s3Container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    pt: { xs: 4.375, md: 6 },
    pb: { xs: 48, sm: 30, md: 12.5, lg: 2.5 },
  },
  s3Col: {
    mb: { xs: '3em', md: 12 },
  },
  s3ColContainer: {
    borderTop: `3.5px solid ${color.onBackgroundLight}`,
    pt: 3,
    mx: 1.5,
  },
  s3ColTitle: {
    pb: 3,
  },
  s3ColBody: {
    mb: '0.5em',
    px: { md: 2, lg: 3.5 },
  },
  s3StoreCol: {
    mb: { xs: 0 },
    textAlign: 'center',
  },
  s3StoreImage: {
    height: 51,
    mt: 0.75,
  },
  s4Container: {
    mx: 'auto',
    pt: { xs: 4.375, md: 6 },
    pb: 2,
    textAlign: 'center',
  },
  s4TitleBox: {
    pt: 4,
    width: '100%',
  },
  s4Subtitle: {
    maxWidth: '34rem',
  },
  s4SubtitleBox: {
    display: 'flex',
    justifyContent: 'center',
    pt: 2,
    width: '100%',
  },
  s4Grid: {
    justifyContent: 'center',
    mt: { xs: 2.375, md: 4 },
    mb: 3.5,
  },
  s4GridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    m: { xs: 1.2, md: 2 },
  },
  s4ImageContainer: {
    borderRadius: 2,
    backgroundColor: color.surfaceLight,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    p: { xs: 1, md: 2 },
  },
  s4Image: {
    width: 48,
    height: 48,
  },
  s5Container: {
    mx: 'auto',
    mb: 3.5,
    pt: { xs: 4.375, md: 6 },
  },
  s5Title: {
    mb: { xs: 3.75, md: 5.75 },
  },
  s5GridRow: {
    display: 'flex',
  },
  s5GridRow1: {
    mb: { xs: 3.75, md: 0 },
    mr: { md: 1.875 },
  },
  s5GridRow2: {
    ml: { md: 1.875 },
  },
  s5GridIcon: {
    height: 24,
    width: 24,
    mr: '1rem',
  },
  s5GridCol: {
    flex: 1,
    pr: 1.5,
  },
  s5GridTitle: {
    mb: 1.875,
    minHeight: 26,
    maxHeight: 26,
  },
  s5GridTitle1: {
    borderBottom: '2px solid',
    color: color.extendedOrange40,
    display: 'inline-block',
    ...mixinFontSizes({ xs: '1.25rem', sm: '1.25rem', lg: '1.25rem' }),
    letterSpacing: '1.3px',
    lineHeight: 1.2,
  },
  s5GridTitle2: {
    borderBottom: '2px solid',
    color: color.extendedRed40,
    display: 'inline-block',
    ...mixinFontSizes({ xs: '1.25rem', sm: '1.25rem', lg: '1.25rem' }),
    letterSpacing: '1.3px',
    lineHeight: 1.2,
  },
  s5GridList: {
    display: 'flex',
    flexDirection: 'column',
  },
  s5GridItem: {
    lineHeight: 1.9048,
  },
  s5BannerBox: {
    color: color.extendedBlue30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pt: 4,
  },
  s5Banner: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: color.extendedBlue90,
    border: `1px solid ${color.neutral95}`,
    borderRadius: { xs: 0, sm: 1.5 },
    mt: 3.5,
    position: 'relative',
    py: 2.5,
    pl: 3,
    pr: 6,
    '&:hover': {
      '@media (hover: hover)': {
        '& > .MuiBox-root': {
          right: 12,
        },
      },
    },
    '&:active': {
      '& > .MuiBox-root': {
        right: 12,
      },
    },
  },
  s5BannerText: {
    letterSpacing: '0.5px',
    lineHeight: 1.3333,
  },
  s5TextSpacing: {
    letterSpacing: '2.5px',
  },
  s5BannerArrowBox: {
    height: '1.1429rem',
    width: '1rem',
    position: 'absolute',
    right: 24,
    transitionDelay: '0s',
    transitionDuration: { xs: '0.2s', md: '0.4s' },
    transitionProperty: 'right',
    transitionTimingFunction: 'ease',
  },
  s5BannerArrow: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    right: 0,
  },
  s6Container: {
    mx: 'auto',
    mb: 3.5,
    pt: 6,
    pb: 2.5,
  },
  s6Content: {
    borderLeft: `1px solid ${color.outlineDark}`,
    pb: 2.5,
    pl: 4,
  },
  s6Title: {
    color: color.neutral95,
    mb: 3.25,
  },
  s6GridItem: {
    color: color.neutral95,
    textAlign: 'center',
  },
  s6Image: {
    borderRadius: '50%',
    height: 150,
    mt: 6.5,
    mb: '1.25rem',
  },
  s6Name: {
    fontWeight: 500,
    lineHeight: '1.35rem',
    pb: 0.75,
    textTransform: 'uppercase',
  },
  s6JobTitle: {
    color: color.secondaryDark,
    maxWidth: '36rem',
    pb: 1.25,
  },
  s6TextBox: {
    maxWidth: '68%',
  },
  s6TextColor: {
    color: color.secondaryDark,
    letterSpacing: '1.3px',
  },
  s6TextSpacing: {
    letterSpacing: '2.5px',
  },
  s6ButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    pt: 7.5,
  },
  polaroidPortrait: {
    bottom: { xs: '118px', sm: '-30px', md: '-60px', lg: '-43px' },
    right: '10.89%',
    width: 203,
    height: 244,
    pt: '11.61px',
    px: '11.61px',
    position: 'absolute',
    backgroundColor: '#fbfdfd',
    backgroundOrigin: 'content-box',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: 'url("images/google-building-43.jpg")',
    borderRadius: 0.5,
    boxShadow: 3,
    transform: 'rotate(5.5deg)',
  },
  polaroidLandscape: {
    bottom: { xs: '-95px', sm: '-90px', md: '-120px', lg: '-103px' },
    left: '7.06%',
    width: 232.26,
    height: 238.06,
    pt: '14.52px',
    px: '14.52px',
    position: 'absolute',
    backgroundColor: '#fbfdfd',
    backgroundOrigin: 'content-box',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: 'url("images/google-car.jpg")',
    borderRadius: 0.5,
    boxShadow: 3,
    transform: 'rotate(-6deg)',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    px: 4.375,
    pt: { xs: 5, md: 5 },
    pb: { xs: 5, md: 12 },
  },
  footerLeft: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: { xs: 'center', md: 'left' },
  },
  footerTitle: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  footerLogo: {
    height: { xs: 30, sm: 30, lg: 34 },
  },
  footerName: {
    fontSize: '1.375rem',
    ...mixinFontSizes({ xs: '1.2375rem', sm: '1.375rem', lg: '1.375rem' }),
    fontWeight: 600,
    letterSpacing: '0.165px',
    lineHeight: 1,
    ml: 1.25,
  },
  footerAddress: {
    fontWeight: 500,
    letterSpacing: '0.4px',
  },
  footerRight: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: { xs: 'center', md: 'right' },
  },
};

const App = function App() {
  const ref = [useRef(), useRef()];
  const [typewriter, setTypewriter] = useState([false, false]);
  const [open, setOpen] = useState(null);

  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const breakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  const inViewport1 = useIntersection(ref[0], '0px');
  const inViewport2 = useIntersection(ref[1], '0px');

  useEffect(() => {
    if (inViewport1) {
      setTypewriter((value) => [true, value[1]]);
    }
    if (inViewport2) {
      if (!breakpointMd) {
        setTypewriter((value) => [value[0], true]);
      }
    }
  }, [inViewport1, inViewport2, breakpointMd]);

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpen = (title, id) => {
    setOpen({
      title,
      id,
    });
  };

  const points1 = ['MOBILE & WEB DEVELOPMENT', 'CLOUD DEVELOPMENT'];
  const points2 = ['PRODUCT DESIGN & ENGINEERING', 'ONLY SENIOR DEVELOPERS'];
  const points3 = ['CLEAN CODE THAT WORKS', 'LOYAL CUSTOMER FOLLOWING'];
  const point = (text, key) => (
    <Box key={key} sx={styles.s2PointBox}>
      <ArrowRightAlt sx={styles.s2Arrow} />
      <Typography sx={styles.s2PointText} variant="subtitle2">
        {text}
      </Typography>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={[styles.sectionLight, styles.bgImageLight]}>
        <Container maxWidth="lg" sx={styles.s1LogoContainer}>
          <Box component="img" src="images/is.svg" alt="ison logo" sx={styles.s1Logo} />
        </Container>
        <Container maxWidth="lg" sx={styles.s1TitleContainer}>
          <Typography align="center" sx={styles.s1Title} variant="h1">
            FULL STACK DEVELOPMENT
          </Typography>
          <Typography align="center" sx={styles.s1TitleSubtitle} variant="h6">
            Ison Solutions Est. 2006
          </Typography>
        </Container>
        <Box sx={styles.stripe} />
        <Box sx={[styles.section, styles.sectionDark, styles.bgImageDark]}>
          <Container maxWidth="lg" style={{ minHeight: 434 }} sx={styles.s2Container}>
            <Box sx={styles.s2TitleBox}>
              <Box sx={[styles.s2TitleLetsPos]}>
                <Typography sx={styles.s2TitleLets}>Let&apos;s</Typography>
              </Box>
              <Typography sx={styles.s2Title} variant="h3">
                Work Together
              </Typography>
            </Box>
            <Box sx={styles.s2Content}>
              <Box sx={styles.s2PointsBox}>
                {points1.concat(points2, points3).map((item, index) => point(item, index.toString()))}
              </Box>
              <Box sx={styles.s2TextBox}>
                <Typography paragraph sx={styles.s2Text} variant="body1">
                  We are a software development shop of 15+ years that works with founders and executives to create
                  custom solutions that are very often less costly than developing inhouse or offshoring.
                </Typography>
                <Box sx={styles.s2PointsBoxXs}>{points1.map((item, index) => point(item, index.toString()))}</Box>
                <Typography paragraph sx={styles.s2Text} variant="body1">
                  From front-end to back-end and everything in between, our developers build rich web and mobile
                  products in collaboration with our client&apos;s technical and non-technical teams.
                </Typography>
                <Box sx={styles.s2PointsBoxXs}>{points2.map((item, index) => point(item, index.toString()))}</Box>
                <Typography paragraph sx={styles.s2Text} variant="body1">
                  We are immensely proud of the impactful products we&apos;ve built.
                </Typography>
                <Box sx={styles.s2PointsBoxXs}>{points3.map((item, index) => point(item, index.toString()))}</Box>
                <Typography paragraph sx={styles.s2Text} variant="body1">
                  Contact us about working together.
                </Typography>
                <Box sx={styles.s2ButtonBox}>
                  <LogoButton
                    onClick={() => handleOpen('Free Consultation', 1)}
                    open={Boolean(open != null && open.id === 1)}
                  >
                    <Typography variant="button">FREE CONSULTATION</Typography>
                  </LogoButton>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box sx={[styles.section, styles.sectionLight]}>
          <Container maxWidth="lg" sx={styles.s3Container}>
            <Box sx={styles.polaroidPortrait} />
            <Box sx={styles.polaroidLandscape} />
            <Grid container>
              <Grid item sx={styles.s3Col} xs={12} md={4}>
                <Box sx={styles.s3ColPadding}>
                  <Box sx={styles.s3ColContainer}>
                    <Typography align="center" sx={styles.s3ColTitle} variant="h6">
                      Web + Mobile Apps
                    </Typography>
                  </Box>
                  <Typography align="center" sx={styles.s3ColBody} variant="body2">
                    Whether building dashboards, responsive web apps or mobile apps, we work collaboratively with our
                    clients to provide a great user experience while achieving the goals of the business.
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.s3Col} xs={12} md={4}>
                <Box sx={styles.s3ColContainer}>
                  <Typography align="center" sx={styles.s3ColTitle} variant="h6">
                    Industrial IOT
                  </Typography>
                </Box>
                <Typography align="center" sx={styles.s3ColBody} variant="body2">
                  Our team has created seemless integrations between industrial sensors of many dozen types and control
                  and monitoring systems supported by web and mobile access. Along the way, we have been named on 2
                  industry patents.
                </Typography>
              </Grid>
              <Grid item sx={[styles.s3Col, styles.s3StoreCol]} xs={12} md={4}>
                <Box sx={styles.s3ColPadding}>
                  <Box sx={styles.s3ColContainer}>
                    <Typography align="center" sx={styles.s3ColTitle} variant="h6">
                      See Our Work
                    </Typography>
                  </Box>
                  <Link
                    aria-label="Apple app store"
                    color="inherit"
                    title="Apple app store"
                    href="https://apps.apple.com/ca/app/cell-towers/id1086819399"
                    rel="noopener"
                    sx={styles.link}
                    target="_blank"
                  >
                    <Box
                      component="img"
                      src="images/apple-app-store.svg"
                      alt="apple app store"
                      sx={styles.s3StoreImage}
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={[styles.section, styles.sectionLight, styles.bgImageLight]}>
          <Container maxWidth="lg" style={{ minHeight: 436 }} sx={styles.s4Container}>
            <Box sx={styles.s4TitleBox}>
              <Typography variant="h4">Technology</Typography>
            </Box>
            <Box sx={styles.s4SubtitleBox}>
              <Typography sx={styles.s4Subtitle} variant="body1">
                We work with current technologies to get your project moving forward quickly.
              </Typography>
            </Box>
            <Grid container sx={styles.s4Grid}>
              {[
                { title: 'JavaScript', name: 'javascript.svg' },
                { title: 'TypeScript', name: 'typescript.svg' },
                { title: 'React', name: 'react.svg' },
                { title: 'Flutter', name: 'flutter.svg' },
                { title: 'Swift', name: 'swift.svg' },
                { title: 'React Native', name: 'react-native.svg' },
                { title: 'Redux', name: 'redux.svg' },
                { title: 'Node.js', name: 'node.js.svg' },
                { title: 'Firebase', name: 'firebase.svg' },
                { title: 'Google Cloud Platform', name: 'google-cloud-platform.svg' },
                { title: 'Google ML', name: 'google-machine-learning.svg' },
                { title: 'Amazon Web Services', name: 'amazon-web-services.svg' },
                { title: 'iOS', name: 'ios.svg' },
                { title: 'Android', name: 'android.svg' },
                { title: 'GitHub', name: 'github.svg' },
                { title: 'Figma', name: 'figma.svg' },
                { title: 'REST & GraphQL', name: 'graphql.svg' },
                { title: 'Elasticsearch', name: 'elasticsearch.svg' },
                { title: 'MongoDB', name: 'mongodb.svg' },
                { title: 'MySQL', name: 'mysql.svg' },
                { title: 'Linux', name: 'linux.svg' },
                { title: 'Python', name: 'python.svg' },
                { title: 'C++', name: 'cplusplus.svg' },
                { title: 'Java', name: 'java.svg' },
              ].map((item) => (
                <Grid item key={item.title} sx={styles.s4GridItem}>
                  <Box component="a" sx={styles.s4ImageContainer} title={item.title}>
                    <Box component="img" src={`images/${item.name}`} alt={item.title} sx={styles.s4Image} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box sx={[styles.section, styles.sectionLight]}>
          <Container maxWidth="lg" style={{ minHeight: 643 }} sx={styles.s5Container}>
            <Typography sx={styles.s5Title} variant="h5">
              Let&apos;s get your project started today.
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box sx={[styles.s5GridRow, styles.s5GridRow1]}>
                  <Box sx={styles.s5GridIcon} />
                  <Box sx={styles.s5GridCol}>
                    <Box ref={ref[0]} sx={styles.s5GridTitle}>
                      {typewriter[0] && (
                        <Typography align="left" sx={styles.s5GridTitle1} variant="h6">
                          <Typewriter
                            options={{ cursor: '', delay: 80 }}
                            onInit={(_typewriter) => {
                              _typewriter
                                .typeString('AREAS WORKED')
                                .callFunction(() => {
                                  setTypewriter([true, true]);
                                })
                                .start();
                            }}
                          />
                        </Typography>
                      )}
                    </Box>
                    <Box sx={styles.s5GridList}>
                      {[
                        'Healthcare',
                        'Environmental Monitoring',
                        'Industrial Safety Systems',
                        'Commercial Construction',
                        'Oil and Gas Process Automation',
                        'Defect Detection',
                        'Residential Housing',
                        'Financial Services',
                        'Mobile & Satellite Communications',
                        'Early-stage start-ups',
                        'Tech companies',
                      ].map((item) => (
                        <Typography key={item} sx={styles.s5GridItem} variant="body1">
                          {item}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={[styles.s5GridRow, styles.s5GridRow2]}>
                  <Box sx={styles.s5GridIcon} />
                  <Box sx={styles.s5GridCol}>
                    <Box ref={ref[1]} sx={styles.s5GridTitle}>
                      {typewriter[1] && (
                        <Typography align="left" sx={styles.s5GridTitle2} variant="h6">
                          <Typewriter
                            options={{ cursor: '', delay: 80 }}
                            onInit={(_typewriter) => {
                              _typewriter.typeString('PRODUCT DEVELOPMENT').start();
                            }}
                          />
                        </Typography>
                      )}
                    </Box>
                    <Box sx={styles.s5GridList}>
                      {[
                        'Startup Founder Idea Exploration',
                        'Engineering Consulting',
                        'Problem Statement Definition',
                        'MVP Feature Identification',
                        'User Journey Mapping',
                        'Agile/Scrum Execution',
                        'UI & UX Design',
                        'Software Development',
                        'App Monetization',
                        'Cloud Configuration and Security',
                        'Image Processing and Storage',
                      ].map((item) => (
                        <Typography key={item} sx={styles.s5GridItem} variant="body1">
                          {item}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={styles.s5BannerBox}>
              <ButtonBase disableRipple onClick={() => handleOpen('Free Consultation', 2)} sx={styles.s5Banner}>
                <Typography component="span" sx={styles.s5BannerText} variant="caption">
                  Our latest project is helping fight cancer.&nbsp;&nbsp;Let&apos;s help you next.
                </Typography>
                <Box sx={styles.s5BannerArrowBox} style={open != null && open.id === 2 ? { right: 12 } : {}}>
                  <ArrowRightIcon sx={styles.s5BannerArrow} />
                </Box>
              </ButtonBase>
            </Box>
          </Container>
        </Box>
        <Box sx={[styles.section, styles.sectionDark, styles.bgImageDark]}>
          <Container maxWidth="lg" style={{ minHeight: 434 }} sx={styles.s6Container}>
            <Typography sx={styles.s6Title} variant="h4">
              OUR TEAM
            </Typography>
            <Box sx={styles.s6Content}>
              <Box sx={styles.s6TextBox}>
                <Typography component="span" variant="body1">
                  Our experienced team provides the passion and creativity required by startups
                </Typography>
                <Typography component="span" sx={styles.s6TextColor} variant="body1">
                  &nbsp;&&nbsp;
                </Typography>
                <Typography component="span" variant="body1">
                  to larger tech companies we can fully service a product area or fill gaps in your technical team.
                </Typography>
                <Typography paragraph variant="body1" />
                <Typography component="span" variant="body1">
                  Interested?
                </Typography>
                <Typography component="span" sx={styles.s6TextSpacing} variant="body1">
                  &nbsp;
                </Typography>
                <Typography component="span" variant="body1">
                  Contact us.
                </Typography>
                <Typography component="span" sx={styles.s6TextSpacing} variant="body1">
                  &nbsp;
                </Typography>
                <Typography component="span" variant="body1">
                  We are always happy to talk.
                </Typography>
                <Typography paragraph variant="body1" />
              </Box>
              <Grid container>
                {[
                  {
                    name: 'Chris Salvador',
                    image: 'profile-chris.jpg',
                    href: 'https://www.linkedin.com/in/chrissalvador',
                  },
                  {
                    name: 'Tim Walter',
                    image: 'profile-tim.jpg',
                    href: 'https://www.linkedin.com/in/tkwalter',
                  },
                ].map((item) => (
                  <Grid key={item.name} item xs={12} md={6} sx={styles.s6GridItem}>
                    <Box component="img" src={`images/${item.image}`} alt={item.name} sx={styles.s6Image} />
                    <Box>
                      <Typography sx={styles.s6Name} variant="subtitle1">
                        {item.name}
                      </Typography>
                      <Typography sx={styles.s6JobTitle} variant="subtitle2">
                        FULL STACK DEVELOPER
                      </Typography>
                      <Link
                        aria-label="Linkedin"
                        color="inherit"
                        href={item.href}
                        rel="noopener"
                        sx={styles.link}
                        target="_blank"
                        title="Linkedin"
                      >
                        <LinkedinIcon />
                      </Link>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box sx={styles.s6ButtonBox}>
                <LogoButton onClick={() => handleOpen('Contact Us', 3)} open={Boolean(open != null && open.id === 3)}>
                  <Typography variant="button">CONTACT US</Typography>
                </LogoButton>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box sx={styles.stripe} />
        <Box sx={[styles.sectionLight, styles.bgImageLight, styles.footer]}>
          <Container maxWidth="lg">
            <Grid container rowSpacing={4}>
              <Grid item xs={12} md={6}>
                <Box sx={styles.footerLeft}>
                  <Link
                    aria-label="Google maps"
                    href="https://www.google.com/maps/place/Ison+Solutions+Ltd./@51.0396845,-114.060229,18z/data=!3m1!4b1!4m5!3m4!1s0x53717702d4d8d2e1:0xfe1bda2cbea46e07!8m2!3d51.0396828!4d-114.059132"
                    rel="noopener"
                    sx={styles.footerAddress}
                    target="_blank"
                    title="Google maps"
                  >
                    <Box sx={styles.footerTitle}>
                      <Box component="img" src="images/is.svg" alt="ison solutions logo" sx={styles.footerLogo} />
                      <Typography color="text.secondary" sx={styles.footerName} variant="h6">
                        Ison Solutions Ltd.
                      </Typography>
                    </Box>
                    <Typography color="text.secondary" sx={styles.footerAddress} variant="subtitle2">
                      300-1331 Macleod Trail SE
                    </Typography>
                    <Typography color="text.secondary" sx={styles.footerAddress} variant="subtitle2">
                      Calgary, AB T2G 0K3
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      {open != null && <ContactForm mobileView={mobileView} onClose={handleClose} title={open.title} />}
    </ThemeProvider>
  );
};

export default App;
